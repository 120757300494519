/* lister item golfbaan */
			
.lister-item-golfbaan {
	margin-bottom: 20px;
	position: relative;

	.lister-item-right {
		border: 1px solid $gt_grey;

    h3.item-title {
      width: 75%;
    }
	}


	.golfbaan-desc {
		width: 75%;
		padding: 0 10px;
		font-weight: 400;
		p {
			margin: 0;
		}
	}

  .golfbaan-list {
		margin: 0 0 0 20px;
		padding: 0 20px 10px 10px;
		li {
			line-height: 22px;
			//font-size: 13px;
		}
	}

  .golfbaan-cta {
		position: absolute;
		bottom: 10px;
		left: 10px;
		@media (max-width: 1024px) {
			position: relative;
		}
	}
}
