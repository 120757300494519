.slider-container{
	height: 300px;
	position: relative;
	.form-container {
		.zoek-widget.home .block-header {
			background-color: $gt_green_transparent;
			margin-bottom: 5px;
		}
			.zoek-widget .btn-group {
				display: block !important;
			}
	}
	
}

#homepage-slider {
	height: 30em;
	border-bottom: 4px solid $gt_green;
	position: relative;
	width: 100%;

  /* Landscape phone to portrait tablet */
  @media (max-width: 767px) {
	    height: 250px;
	}

  .block {
		box-shadow: 0px 0px 6px 0px rgba(50, 50, 50, 0.75);
		border-radius: 4px;
	}

  .form-container {
		position: absolute;
		width: 100%;
		left: 0;
    top: 0;
		height: 100%;
		display: flex;
		align-items: center;
		text-align: center;

    h1 {
			color: #fff;
			font-size: 3.25rem;
			display: inline-block;
			text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
			box-shadow: 0px 0px 70px 40px rgba(0, 0, 0, 0.05);
			background-color: rgba(0, 0, 0, 0.05);
			font-weight: 400;
			@media (max-width: 767px) {
			    font-size: 22px;
			    font-weight: 400;
			}
		}

    .zoek-widget {
			margin: 0;
		}
	}

	figure {
    // position: absolute;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      // object-position: center top;
    }
	}

	.photo-title {
		position: absolute;
		right: 20px;
		bottom: 20px;
		color: #fff;
		font-family: $gt_heading_font;
	}
}
