.detail-page {

  .detail-header {
		padding: 10px 20px;
		background-color: $gt_grey_light;
		border-top: 1px solid $gt_grey;
		border-left: 1px solid $gt_grey;
		border-right: 1px solid $gt_grey;
	}

  .info-page-content {
		padding: 30px 20px;
		border: 1px solid $gt_grey;
		font-size: 16px;
		a:not(.btn) {
			color: $gt_green;
			text-decoration: underline;
			font-weight: 400;
		}
	}

  .blog-header {
		padding-top: 50px;
		min-height: 400px;
		margin-bottom: 40px;
		h1 {

			background-color: rgba($gt_green, 0.6);
			padding: 10px 20px;
			color: #fff;
		}
		.author {
			display: table;
			margin-top: 20px;
			background-color: rgba($gt_green, 0.9);
			.author-img, .author-meta {
				padding: 10px;
				color: #fff;
				display: inline-block;
			}
			.author-meta {
				padding-left: 0;
				padding-right: 20px;
				display: table-cell;
				vertical-align: middle;
			}
		}
	}

	.detail-description {
		img {
			max-width: 100%;
			margin: 20px 0;
		}
		p {
			font-size: 16px;
		}
	}

  h1 {
		font-size: 25px;
		line-height: 1.2;
		margin: 0;
		color: $gt_green;
		@media (max-width: 767px) {
			font-size: 20px;
		}
	}

  .location {
		a {
			color: $gt_green;
			font-weight: 400;
		}

	}

  .title-stars {
		i {
			display: table-cell;
			vertical-align: top;
			font-size: 50%;
			line-height: 20px;
			color: $gt_green;
		}
	}

  .detail-main-image,
  .main-img-header {
		position: relative;
		.score {
			position: absolute;
			top: 10px;
			right: 10px;
			z-index: 1;
		}
	}

  .rb .detail-usps {
		padding-right: 75px;

	}

  .rb .wrapper-rb {
		right: 15px;
	}

  .detail-usps {
		li {
			font-size: 16px;
			font-weight: 400;
			color: $gt_green;
		}
		li:nth-child(n+4) {
			display: block;
		}
	}

  .detail-heading {
		font-size: $gt_font-size-bigger;
		font-weight: 700;
		line-height: 1.5;

	}

  article {
		margin: 40px 0;
		p {
			line-height: 1.7;
		}
	}

  #ytplayer {
		max-width: 100%;
	}

  .detail-faciliteiten {
		ul > li > ul {
			padding-left: 0;
			margin: 20px;
		}
	}

  .nav > li > span.hash-link {
	    margin-right: 2px;
	    line-height: 1.42857;
	    border: 1px solid transparent;
	    border-radius: 4px 4px 0 0;
	    position: relative;
	    display: block;
	    padding: 10px 15px;
	    font-size: 16px;
		color: $gt_green;
		cursor: pointer;
	}

  #cta-mobile {
		background-color: rgba($gt_green, 0.8);
		padding: 10px;
		width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1000;

    a {
      padding: 10px 64px;
    }
	}

  .share-section {
		i {
			font-size: 25px;
			color: $gt_green;
			margin-right: 10px;
		}
	}

  .blog-footer {
		background-color: $gt_grey_light;
		margin: 20px 0;
		padding: 10px 20px;
		display: flex;
		border: 1px solid $gt_grey;

    .author-img {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: 20px;
		}

	}
}

.container {
  .panes-footer {
    a {
      padding: 10px 64px;
    }
  }
}

.panel-travelcaddy {
  .advisor-image {
    margin-top: 20px;
  }
}

.author-img {
	img {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		background-color: $gt_grey_light;
		border: 2px solid $gt_green;
	}
}

.blog-list {
	.list-group-item {
		display: flex;
		.author-img{
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: 10px;
		}
	}


}

.detail-course {
	ul.faciliteiten-list {
		&.cols {
			column-count: 3;
		}
		padding: 20px;
		li {
			padding-left: 20px;
			i {
				margin-left: -25px;
			}
			.key {
				font-weight: 400;
			}
			.value {
				text-transform: capitalize;
			}
			a {
				color: $gt_green;
				font-weight: 400;
				text-decoration: underline;
			}
		}
	}
}
a[href="#moreAccos"] {
	margin-bottom: 20px;
}
a[href="#moreAccos"][aria-expanded="true"] {
	display: none;
}
