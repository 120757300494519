.packages {
	.package {
		.panel-heading {
			text-align: center;
			.package-name {
				font-size:18px;
				font-weight: 600;
				display: block;
			}
			
		}
		.package-days-price {
			font-size: 13px;
			font-weight: 400;
		}
		.panel-body {
			padding: 0;
			.package-meest-gekozen {
				position: absolute;
				top: -1px;
				left: -1px;
				font-size: $gt_font-size-smallest;
				padding: 3px 5px;
				line-height: 1;
				background-color: $gt_grey;
				border: 1px solid $gt_grey;
			}
			position: relative;
			overflow: hidden;
			ul {
				padding-left: 15px;
			}
		}
		.panel-footer.package-footer {
			a.package-cta{
				color: #fff;;
				display: block;
				line-height: 1.5;
				padding: 10px 0;
				font-size: 11px;
				text-decoration: none;
				padding: 1px 15px;
				small {
					line-height: 1;
				}
				.prijs {
					font-size: 20px;
				}
			}
			margin: 0;
			padding: 5px 10px;
			background-color: $gt_red;
			text-align: center;
			&:hover {
				background-color: darken($gt_red, 5%);
			}
		}
		.package-specs {
			> ul {
				padding: 0;
				margin: 0;
				> li {
					position: relative;
					.package-icon, .package-spec {
						
						
					}
					.package-spec {
						left: 50px;
						position: relative;
						height: auto;
						width: calc(100% - 50px);
						padding: 5px 20px;
						
					}
					.package-icon {
						position: absolute;
						background-color: $gt_grey_light;
						color: $gt_green;
						width: 50px;
						left: 0;
						top: 0;
						bottom: 0;
						display: flex;
						align-items: center;
  						justify-content: center;
						text-align: center;
						i {
							vertical-align: middle;
						}
						border-right: 1px solid $gt_grey;
					}
					&:after {
						content: '';
						clear: both;
						display: table;
					}
					border-bottom: 1px solid $gt_grey;
					list-style: none;
					.greenfees-list {
						list-style-type: disc;
					}
				}
				
			}
			a {
				color: $gt_green;
				font-weight: 400;
			}
		}
	}
	#accordion {
		margin-bottom: 0;
	}
	&:not(.packages-small) {
		.package-specs {
			display: block;
		}
		.panel-heading.sub a[data-toggle="collapse"] {
			display: none;
		}
	}
	&.packages-small{
		h3.acco-title:first-child {
			margin-top: 0;	
		}
		h3 a {
			color: $gt_green;
			font-weight: 400;
		}
	}
}
ul.package-order-blocks {
	padding-left: 0;
	margin-right: 70px;
	> li {
		list-style-type: none;
		list-style-position: outside;
		font-size: 13px;
		position: relative;
		padding-left: 20px;
		a {
			color: $gt_black;
		}
		i {
			color: $gt_green;
			position: absolute;
			left: 0;
			top: 5px;
		}
		.sublist {
			padding-left: 30px;
		}
	}
}
