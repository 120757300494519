
/* BLOCK */

.block {
	.block-header {
		background-color: $gt_green;
		padding: 15px 20px;

    h2 {
      color: #FFF;
      margin: 0;
      font-size: 20px;
    }
	}

  .block-body {
		padding: 20px;
    h3 {
      font-size: 16px;
    }

    .form-group {
      &.checkboxes {
        margin-bottom: 40px;
      }
    }
	}
}

/* WIDGET */
.location-header-container #zoek-widget {
	position: absolute;
	bottom: 0;
}

.widget {
	position: relative;
	margin-bottom: 40px;
	box-shadow: $gt_box_shadow;

	&:hover {
		box-shadow: $gt_box_shadow_hover;
	}

	.location {
		background-color: rgba(255,255,255, 0.8);
		padding: 5px;
		position: absolute;
		margin-top: 5px;
		left: 5px;
		
		a {
			color: $gt_green;
		}
	}

	h3.widget-header {
		background-color: $gt_grey_light;
		border-top: 1px solid $gt_grey;
		border-left: 1px solid $gt_grey;
		border-right: 1px solid $gt_grey;
		font-size: 14px;
		color: $gt_black;
		line-height: 1.5;
		padding: 10px;
		white-space: nowrap;
		overflow: hidden;
		margin: 0;
		font-weight: 400;
		position: absolute;
		width: 100%;
	}

	.content-row {
		margin: 20px 0;
	}

	.widget-image {

		@media (min-width: 992px) {
	    max-height: 300px;
			overflow-y: hidden;
		}
		
		img {
			width: 100%;
			height: auto;
			
		}
	}

	.widget-footer {
		position: absolute;
		width: 100%;
		bottom: 0;

		.btn {
			border-radius: 0;
		}
	}

}

/* Box
------------------------------ */

.box {
	background-color: #FFFFFF;
	box-shadow:         0px 0px 20px 0px rgba(50, 50, 50, 0.3);
	padding: 10px 20px;
	margin-bottom: 20px;
}

.box h3.box-title {
	font-size: 20px;
	color: #000;
	line-height: 1.3;
}

.box p {
	font-size: 12px;
}
