.slick-slider .slick-arrow {
	z-index: 9 !important;
}
.gallery-thumbnails {
	@media (max-width: 480px) {
		display: none;
	}
	&::after {
	    clear: both;
	}
	&::after, &::before {
	    content: " ";
	    display: table;
	}
	margin-left: -2px;
	margin-right: -2px;

	.gallery-thumbnail {
		float: left;
		width: calc(100% / 8);
		position: relative;
		min-height: 1px;
		padding-left: 2px;
		padding-right: 2px;
		margin: 2px 0;
		.toggle-media {
			.more-media {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba($gt_green, 0.8);
				color: #fff;
				text-align: center;
				padding: 20px 0;
				line-height: 1;
				font-size: 30px;
			}
			&[aria-expanded="true"] {
				display: none;
			}
		}
	}

}
