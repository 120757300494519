/* Calendar
------------------------------ */
.calendar-container {
	.loader {
		display: none;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		text-align: center;
		background-color: rgba(0,0,0, 0.2);
		padding-top: calc(50% - 40px);
		i {
			font-size: 40px;
		}
		z-index: 50;
	}
	&.loading {
		.loader {
			display: block;
		}
	}

}


table.calendar {
  width: 100%;
}

.detail-page .calendar-table {
	h4 {
		color: $gt_green;
	}
	h5 {
		color: $gt_green;
		font-size: 14px;
		margin-bottom: 0;
	}

  .detail-usps li {
		font-size: 13px;
		color: inherit;

    svg {
      height: 13px;
      fill: $gt_green;
    }
	}

}
.calendar-wrapper {

	.calendar-header {
		text-align: center;
	}

	.calendar-day {
		position: relative;
		padding: 0;

  	.available, .not-available {
			padding: 5px;
		}

  	.day {
			position: absolute;
			top: 0;
			right: 0;
			padding: 3px;
			width: 25px;
			text-align: center;
			font-size: 11px;
			border-left: 1px solid #ccc;
			border-bottom: 1px solid #ccc;
		}

		.price {
			padding-top: 30px;
			font-weight: 400;
			position: relative;
			width: auto;
			min-width: 14%;

			span._euro {
				display: inline-block;
				margin-right: .5ch;
			}
		}
	}

	.calendar-day-event {
		&.active{
			background-color: $gt_green;
			color: #fff;
			&:hover {
				background-color: $gt_green;
				color: #fff;
			}
		}
		&:hover  {
			background-color: $gt_grey;
		}
	}

	&.slick-slider .slick-arrow {
	    // top: -20px;
    top: 20px;

    svg {
      height: 30px;
    }

    &.slick-prev-alt {
      left: 0;
    }

    &.slick-next-alt {
      right: 0;
    }

    &.slick-disabled {
    	svg {
        display: none;
      }
    }

	}

}

@media (max-width: 390px) {
  .calendar-wrapper {
    .calendar-day {
      .price {

        span._euro {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 420px) {
  .calendar-wrapper {
    .calendar-day {
      .price {

        span._euro {
          margin-right: 0;
        }
      }
    }
  }
}

@media (max-width: 855px) {
  .calendar-wrapper {
    .calendar-day {
      .price {
        font-size: 13px;
      }
    }
  }
}



