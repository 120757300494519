/* HEADER */

header {
  .basic-header {
    .logo-row {
      img.logo {
        margin : 10px 0;
        max-width: 200px;
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
  }
}



// .header-top-row {
//  background-color: $gt_green;
//  color: #fff;

//  ul {
//    float: right;

//    li {
//      display: inline-block;
//      padding: 0 10px;
//      line-height: 1.8;
//      margin: 5px 0;
//      border-right: 1px solid #fff;
//      font-weight: 300;
//      font-size: 12px;
//      &:last-child {
//        border: none;
//      }
//    }
//  }

//   a {
//    color: #fff;
//    text-decoration: none;
//    &:hover {
//      color: #fff;
//      text-decoration: underline;
//    }
//  }
// }

// #menu-topmenu {
//  width: 100%;
//  box-shadow: 2px -2px 3px 0 rgba(50, 50, 50, 0.75);
//  position: relative;
//  z-index: 10;
//  margin-top: 20px;

//  li {
//    float: left;
//    list-style: none;
//    width: 20%;
//    text-align: center;
//    background-color: rgba(0,167,184,0.7);
//    color: #fff;
//    &.mark a {
//      color: #6C7BC8;
//    }
//    a {
//      color: #fff;
//      line-height: 2;
//      font-size: 12px;
//      font-weight: 300;
//      letter-spacing: 1px;
//      &:hover {
//        color: $gt_red;
//        text-decoration: none;
//      }
//    }

//  }
// }

.logo-row {
  padding: 15px 0;

  .logo-row-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo-row-right {
      display: flex;
      align-items: center;
    }
  }

  .keurmerken {
    margin: 0;
    padding: 0 10px;
    border-right: 1px solid $gt_green;

    img {
      height: 30px;
      width: auto;
      margin: 8px 10px;
      // filter: grayscale(100%);

      // &:hover {
      //   filter: none;
      // }
    }
  }

  .contact-block {
    aside {
      margin: 0;
      text-align: left;
      padding: 0 10px;

        a {
          color: $gt_green;
          font-size: 15px;
          line-height: 1.5;
          font-weight: 400;
          text-decoration: none;
          display: block;
          margin: 0 10px;

          i {
            margin-right: 5px;
          }

          &:hover {
            color: $gt_red
          }
        }


    }
  }
}


.navbar-default {
  border: none !important;
  font-smoothing: antialiased;
  background-color: #fff;

  .navbar-toggle {
    padding-bottom: 13px;
    border-color: $gt_black;
    color: $gt_black;

    &.toggle-modal {
      padding: 6px 10px;
      margin-right: 5px;

      svg {
        fill: $gt_black;
      }
    }

    .icon-bar {
      background-color: $gt_black;

      + .icon-bar {
        margin-top: 2px;
      }
    }

    .menu-text {
      font-size: 10px;
      position: absolute;
      // bottom: 2px;
      left: 8px;
    }
  }
}



.navbar {
  border-radius: 0;
  min-height: 0;
  margin: 0;
}

.navbar-collapse {
  padding: 0;
}



.mainmenu-row {
  border-top: 1px solid $gt_green !important;
  border-bottom: 1px solid $gt_green !important;

  box-shadow: none;

  background: none;
  background-color: $gt_green;

  li {
    &.dropdown {
      svg {
        fill: #fff;
        height: 13px
      }
    }
  }
}


// .mainmenu-row.fixed {
//   position: fixed;
//   top: 0;
// }



#mainmenu {
  margin: 0;
  display: flex;
  justify-content: space-between;

  li {
    list-style: none;
    padding: 15px 5px;

    &.aanbiedingen-en-last-minutes {
      a {
        background-color: $gt_red;
        padding: 5px 10px;
        border-radius: 2px;

        &:hover {
          background-color: darken($gt_red, 5%);
        }
      }
    }
  }

  a {
    font-size: 16px;
    font-weight: $gt_font_weight_menu;
    color: #fff;
    text-decoration: none;

    &:hover {
      color: $gt_grey;
    }
  }

  .dropdown-menu {
    box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.4);

    &.multi-column {
      min-width: 380px;
      margin-top: 0px;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      background-color: $gt_grey_light;
      width: 100%;
    }


    ul.dropdown-menu-list {
      li {
        padding: 5px 0 5px 15px;
      }
    }

    h5 {
      padding: 5px 15px 10px 15px;
      margin: 0;
      border-bottom: 1px solid $gt_grey;
      color: $gt_black;
      font-weight: 700;
    }


    .btn {
      margin: 15px;
      color: #fff;
      font-size: 12px;

      &:hover {
        color: #fff;
      }
    }

    a {
      color: $gt_black;
      font-weight: 400;

      &:hover {
        color: $gt_red;
      }
    }
  }

}

@media ((min-width: 768px) and (max-width: 991px)) {
  .logo-row {
    .logo-row-wrapper {
      > a {
        > img {
          height: 45px;
          max-width: auto;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {

  header {
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: #fff;
    border-bottom: 1px solid $gt_border_color;
    box-shadow: 0 0 3px rgba(0,0,0,0.3);
  }

  .mainmenu-row {
    background-color: #fff;
    border-top: none !important;
  }

  .navbar {
    // position: relative;
    .collapse {
      &.in {
        overflow-y: visible;
      }
    }
    z-index: 6;
  }

  .navbar-default {
    #main-collapse {
      background-color: #fff;
    }
  }

  #mainmenu {
    flex-direction: column;

    li {
      margin: 0;
      padding: 0;

      border-bottom: 1px solid $gt_grey;

      &.aanbiedingen-en-last-minutes {
        padding: 0;

        a {
          display: block;
          padding: 15px;
          color: #fff;
        }
      }

      a:not(.btn) {
        color: $gt_black;
        display: block;
        padding: 15px;
      }
    }

    svg {
      fill: $gt_black;
    }

    .dropdown-menu {
      max-height: 50vh;
      overflow-y: auto;
      overflow-x: hidden;

      &.multi-column {
        min-width: unset;
      }

      .row {
        margin: 0;
      }

      .col-sm-6 {
        margin: 0;
        padding: 0;
      }

      li {
        padding: 0;
        margin: 0;
      }

      ul {
        &.dropdown-menu-list {
          li {
            padding: 0;
          }
        }
      }
    }
  }

  img.logo-sm {
    float: left;
    width: 100%;
    padding: 13px;
    max-width: 215px !important;
  }
}

@media (max-width: 370px) {
  img.logo-sm {
    width: 100%;
    padding: 16px;
    max-width: 170px !important;
  }
}


@media (min-width: 768px) and (max-width: 991px) {
  #mainmenu {
    li {
      &.aanbiedingen-en-last-minutes {
        a {
          font-size: 80%;
        }
      }
    }
  }
}

.usp-row {
  border-bottom: 1px solid $gt_green;
  background-color: $gt_grey_light;
  padding: 5px 0;

  &.usp-row-home {
    border: none;
  }

  &.usp-row--home {
    @media (min-width: 767px) {
      display: none;
    }
  }

  .gt-usp-list {
    margin: 0;
    width: 100%;
    white-space: nowrap;
    list-style: none;
    display: flex;
    justify-content: space-between;

    &.gt-usp-list-home {

      flex-direction: column;
      padding-left: 40px;

      li {
        display: block !important;
        width: 100% !important;
        text-align: left !important;

        padding: 10px 0;

        a {
          display: inline-block;
          line-height: 1.5;
          font-size: 18px;
          border-bottom: 1px dotted $font-color;
          margin-left: 23px;

          svg {
            height: 18px;
            margin-bottom: -3px;
            fill: $gt_green;
            margin-left: -23px;
          }
        }



      }
    }
  }

  .gt-usp-list li {

    &.keurmerken-small {
      display: none !important;
    }

    @media (max-width: 767px) {
        display: inline;
        text-align: left;
        &.keurmerken-small {
          display: inline-block !important;
        }

        img {
          height: 16px;
          margin-right: 4px;
          width: auto;
        }
    }
  }

  .gt-usp-list li {
    a {
      font-size: 12px;
      color: $gt_black;
      text-decoration: none;
      font-weight: 500;
    }

    svg {
      height: 12px;
      margin-bottom: -2px;
    }
  }


}

// .contact-list {
//   list-style: none;
//   margin: 20px 0;
//   padding: 0;
//   position: relative;
//   z-index: 10;
// }

// .contact-list li {
//   float: left;
//   list-style: none;
//   margin-left: 10px;
// }

// .contact-list li a {
//   color: $gt_red;
// }


// .topmenu, .topmenu .menu {
//   width: 100%;
//   position: relative;
//   z-index: 9;
//   height: 54px;
// }

//   .topmenu li {
//     float: left;
//     list-style: none;
//     width: 20%;
//   }
//   .menu-widget, .small-menu-slider .widget {
//     margin: 10px 25px 0;
//     box-shadow: none;
//   }
//   .small-menu-slider .widget-image img, .menu-widget .widget-image img {
//     -webkit-filter: grayscale(100%);
//     filter: grayscale(100%);
//     filter: gray;
//     filter: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' height='0'><filter id='greyscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0' /></filter></svg>#greyscale")

//   }
//   .small-slider .ajax-loader {
//     display: none;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     z-index: 10000;
//     margin-left: -15px;
//     margin-top: -15px;
//   }
//     .small-slider.loading .ajax-loader {
//       display: block;
//     }
//   .small-menu-slider .widget a.control {
//     padding: 5px;
//     background-color: transparent;
//   }
//     .small-menu-slider .control img {
//       width: 5px;
//       height: 5px;

//     }
//     .small-menu-slider .widget a.control.next {
//       right: -15px;
//     }
//     .small-menu-slider .widget a.control.prev {
//       left: -15px;
//     }
//   .logo-row img.logo {
//     max-width: 100%;
//     height: auto;
//     margin: 0;
//   }
//   img.logo-sm {
//     float: left;
//     padding: 13px;
//     max-width: 215px !important;
//   }
//
//

//   }
//   #partner-image-carousel .small-slider-image img {
//     width: 100%;
//     height: auto;
//   }
// .partner-row {
//   background-color: #DADBD9;
// }
//   .partner-row .pay-off {
//     color: #FFF;
//     line-height: 40px;
//     font-size: 16px;
//   }
//   .partner-row .partners {
//     line-height: 40px;
//     color: #757747;
//     margin-right: 20px;
//   }
//     .partner-row .partners img {
//       max-height: 20px;
//       width: auto;
//       margin-left: 5px;
//     }
// .partner.widget a.widget-cta {
//   bottom: -60px !important;
// }
// .widget.partner .vouw {
//   bottom:0 !important;
// }


// #mainmenu .dashicons-admin-home {
//   font-size: 22px;
//   margin: 16px 0 20px;
// }

// .in #mainmenu > li, .collapsing #mainmenu > li {
//   display: block;
// }
