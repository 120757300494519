/* LOCATION PAGE */
.single-bestemmingen {
	footer {
		margin-top: 0;
	}
	.color-row {
		margin-bottom: 0;
	}
}
#golfbanen-carousel .slick-slider .carousel-item {
	margin-bottom: -5px;
}
.location-header-container {
	position: relative;
	height: 420px;
}

.location-header {
	position: absolute;
	bottom: 0;
	z-index: 1;
}
.location-recom {
	display: none;
}

#page-nav {
	.navbar-nav {
		float: none;
		display: table;
		margin: 0 auto;
	}
}
#moreCourses {
	display: block;
	text-align: center;
	position: absolute;
	z-index: 100;
	width: 100%;
	height: 150px;
	background-image: linear-gradient(to bottom, rgba(255,255,255,0.2), rgba(255,255,255,1));
	a {
		font-size: 16px;
		font-weight: 700;
		padding-top: 80px;
		color: $gt_green;
		display: block;
	}
}
.toggle-state {
	height: 150px;
	overflow: hidden;
}
#over {
	.arrow {
		text-align: center;
		i {
			font-size: 40px;
			color: $gt_green;
		}
	}
}
#current-weather {
	padding:20px;
	// background:url(../../images/weatherbg.jpg) no-repeat;
	background-size:cover;
	border-radius:2px
}
#current-weather .cw-wrap {
	background: $gt_green;
	padding:5px 15px;
	border-radius:2px
}
#current-weather h3 {
	color:#fff;
	border-bottom:1px solid #fff
}
#current-weather ul {
	width:100%;
	padding-left:0
}
#current-weather li {
	list-style:none;
	width:100%;
	border-bottom:1px solid rgba(255,255,255,.5);
	color:#fff
}
#weather .current-temp {
	color:#fff;
	font-size:70px;
	display:block;
	margin-top:15px;
	letter-spacing:-3px
}
#weather .main-icon {
	font-size:100px;
	position:relative;
	top:15px;
	color:#fff
}
#weather td {
	border:none
}
#weather td i {
	display:block;
	font-size:20px;
	color:#ccc
}
#weather .table td {
	vertical-align:middle
}
@media (min-width: 1700px) {
	.location-recom {
		display: block;
		position: fixed;
		bottom: 0;
		left: 0;
		max-width: 300px;
		background-color: #fff;
	}
	.location-recom img {
		width: 50%;
		float: left;
		position: relative;
		bottom: 0;
	}
	.location-recom .quote {
		color: $gt_green;
		float: left;
		width: 50%;
		font-size: 15px;
		font-weight: 400;
		padding: 10px 10px 0 10px;
		font-style: italic; 
		.btn {
			font-family: $gt_body_font;
			font-style: normal;
			margin-top: 10px;
		}
	}
}
.zoek-widget.location .block-header {
	background-color: rgb(77,80, 62);
	background-color: rgba(77,80, 62, 0.9);

}
.info-slider {
	padding: 0 80px;
	i.fa-chevron-circle-left, i.fa-chevron-circle-right {
		color: $gt_green;
	}
	.info-box {
		text-align: center;
		p {
			font-size: 16px;
		}
		i {
			font-size: 40px;
			color: $gt_green;
		}
		h4 {
			color: $gt_green;
		}
	}
}

/* Finder */

.location-finder {
	margin: 10% 0;
	padding: 20px;
	background: $gt_green_transparent;
	
	h1 {
		text-align: center;
		color: #fff;
		font-weight: 300;
		line-height: 1.1;
		margin: 0 0 20px 0;
		font-size: 35px;
		text-transform: inherit;
		@media (max-width: 670px) {
			font-size: 20px;
		}
	}
	form {
		margin-top: 20px;
		.form-control {
			color: #000;
		}
		label {
			color: #fff;
			font-weight: 400;
			font-size: 16px;
			text-align: left;
			font-family: Oswald,sans-serif;
		}
		.input-group-addon {
			background-color: #fff;
		}
	}
	&.sticky {
		position: fixed;
		z-index: 110;
		left:0;
		width: 100%;
		bottom: 0;
		margin: 0;
		@media (max-width: 768px) {
			.hidden-sticky-sm {
				display: none;
			}
		}
		h1 {
			font-size: 25px;
			@media (max-width: 670px) {
				font-size: 15px;
				margin-bottom: 0
			}
		}
		form {
			max-width: 1200px;
			margin: 0 auto;
		}
	}
}
@media (max-width: 670px) {
	.lister-top-row .btn {
		width: 90%;
		margin: 0 5% 10px 5%;
	}
	.golfbaan-top-row .golfbaan-desc {
		width: 75%;
	}
	.golfbaan-top-row .golfbaan-rating {
		width: 25%;
	}
}

.top-list {
	h2 {
		text-align: center;
		color: #fff;
		line-height: 1.2;
		span {
			font-size: 16px;
			display: block;
			text-transform: inherit;
		}
	}
	.main-img-header{ 
		min-height: 350px;
	}
}
