
.detail-page {

  .campaign {
    &-header {
      padding-top: 29rem;

      h1 {
        padding: 1rem 2rem;
        background-color: rgba(28, 74, 64, 0.6);
        color: #fff;
        // margin-top: 29rem;
      }
    }
  }

}
