
/* ICONEN */
i[class*=icon-] {
	background-image: url(../../images/iconen.png);
	background-position: 0 -20px;
    background-repeat: no-repeat;
    display: inline-block;
    height: 20px;
    line-height: 20px;
    margin-top: 1px;
    vertical-align: text-bottom;
    width: 20px;
    margin-right: 5px;
}

.socials svg {
	font-size: 20px;
	fill: $gt_green;

	width: 40px;
	height: 40px;

	border: 1px solid $gt_green;
	border-radius: 50%;

  padding: 3px;
	margin: 0 5px;

  &:hover {
		fill: #fff;
		background-color: $gt_green;
	}
}

i.icon-3 {
	background-position: -40px -20px;
}
i.icon-3.black {
	background-position: -40px 0px;
}
i.icon-3.white {
	background-position: -40px -40px;
}
i.icon-airport {
	background-position: -60px -20px;
}
i.icon-airport.black {
	background-position: -60px 0px;
}
i.icon-airport.white {
	background-position: -60px -40px;
}
i.icon-autohuur {
	background-position: -80px -20px;
}
i.icon-autohuur.black {
	background-position: -100px 0px;
}
i.icon-autohuur.white {
	background-position: -80px -40px;
}
i.icon-greenfees {
	background-position: -100px -20px;
}
i.icon-greenfees.black {
	background-position: -80px 0px;
}
i.icon-greenfees.white {
	background-position: -100px -40px;
}

i.icon-7 {
	background-position: -120px -20px;
}
i.icon-7.black {
	background-position: -120px 0px;
}
i.icon-7.white {
	background-position: -120px -40px;
}
i.icon-53, i.icon-9 {
	background-position: -140px -20px;
}
i.icon-53.black, i.icon-9.black  {
	background-position: -140px 0px;
}
i.icon-53.white, i.icon-9.white {
	background-position: -140px -40px;
}
i.icon-8 {
	background-position: -160px -20px;
}
i.icon-8.black {
	background-position: -160px 0px;
}
i.icon-8.white {
	background-position: -160px -40px;
}
i.icon-49 {
	background-position: -180px -20px;
}
i.icon-49.black {
	background-position: -180px 0px;
}
i.icon-49.white {
	background-position: -180px -40px;
}
i.icon-4, i.icon-5 {
	background-position: -200px -20px;
}
i.icon-4.black, i.icon-5.black {
	background-position: -200px 0px;
}
i.icon-4.white, i.icon-5.white {
	background-position: -200px -40px;
}
i.icon-35 {
	background-position: -220px -20px;
}
i.icon-35.black {
	background-position: -220px 0px;
}
i.icon-35.white {
	background-position: -220px -40px;
}
