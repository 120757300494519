/* REVIEWS */
.review-wrap{
	margin-bottom: 20px;
}

.review {
  p {
  	font-size: 16px;
  	line-height: 20px;
  }

  .review-details{
    color: #a0a0a4;
  }
}

.review-icon{
	width: 70px;
	height: auto;
	border-radius: 50%;
	background-color: #fff;
}

.featuredReview {
	border-radius: 5px;
	padding:10px 0;
	background-color: #ececf2;

  .review-icon{
    border:1px solid #b7c107;
  }

  small {
    display: block;
    color: #b7c107;
  }
}


.review-list {
	list-style: none;
	padding-left: 0;
	margin-bottom: 40px;

	li {
		font-size: 15px;
		font-weight: 300;
		line-height: 2;

		strong {
			font-size: 22px;

			color: $gt_green;
		}

		svg {
      height: 15px;
			fill: $gt_green;
      margin-bottom: -2px;
		}
	}
}

.review {
	position: relative;
	margin-bottom: 40px;

	blockquote {
		font-size: 15px;
		border-left: 3px solid $gt_green;
		min-height: 60px;
		padding-right: 70px;

    @media (max-width: 768px) {
			padding-right: 30px;
			min-height: 110px;
		}
	}

	.review-rating {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		font-size: 30px;
		color: #fff;
		background-color: $gt_green;
		width: 50px;
		height: 50px;
		text-align: center;
		border-radius: 50%;
		line-height: 50px;

    @media (max-width: 768px) {
			font-size: 20px;
			background-color: $gt_green;
			width: 30px;
			height: 30px;
			text-align: center;
			border-radius: 50%;
			line-height: 30px;
			
		}
	}
	.review-meta {
		font-weight: 700;
		font-size: 13px;
	}
}

#review-slider{

	padding: 0 15%;
	@media (max-width: 768px) {
		padding: 0 30px;
		.carousel-control.left {
			left: -20px;
		}
		.carousel-control.right {
			right: -20px;
		}
	}

	.carousel-control.left, .carousel-control.right {
		background: none !important;
		height: 100%;
		i {
			color: $gt_green;
			font-size: 20px;
			line-height: 1;
			padding-top: 20px;
			@media (max-width: 768px) {
			    padding-top: 40px;
			}
		}
	}

	.item {

      transition-property: opacity;
    }

    .item,
    .active.left,
    .active.right {
        opacity: 0;
    }

    .active,
    .next.left,
    .prev.right {
        opacity: 1;
    }

    .next,
    .prev,
    .active.left,
    .active.right {
        left: 0;
        transform: translate3d(0, 0, 0);
    }
}

.golfbaan-rating {
	position: absolute;
	right: 0;
	top: 0;
	background-color: $gt_green;
	color: #fff;
	text-align: center;
	width: 25%;
	max-width: 100px;
	padding: 10px 0 0;

	.golfbaan-rating-number {
		font-size: 30px;
		text-align: center;
		line-height: 40px;
	}

  .golfbaan-rating-stars {
    svg {
      fill: #fff;
      height: 10px;
      padding: 0;
      margin: 0;
    }
  }

	.golfbaan-rating-holes {
		background-color: $gt_grey;
		padding: 10px 5px;
		color: $gt_black;
		text-align: center;
		.big {
			font-size: 18px;
			font-weight: 400;
		}
	}
}
.review-wrap {
	.golfbaan-rating {
		position: initial;
		width: 100%;
		max-width: none;
		margin-bottom: 20px; 
	}
}

.lister-item-small {
  .golfbaan-rating {
    .golfbaan-rating-stars {
      svg {
        height: 8px;
        margin: 0 -1px;
      }
    }
  }
}
