/* Colors */
$gt_green: #1c4a40;
$gt_green_transparent: rgba($gt_green, 0.9);
$gt_yellow_bright: #fae335;
$gt_red: rgb(255,90,96);
$gt_green_light: #52ae32;
$gt_orange: #f7a600;
$gt_black: #373b3e;
$gt_grey_dark: #aaa;
$gt_grey: #ddd;
$gt_grey_light: #f5f5f5;
$gt_font_weight_body: 300;
$gt_font_weight_heading: 300;
$gt_font_weight_menu: 300;

/* Fontsasdasdasd */
//$gt_body_font: 'Quicksand', sans-serif;
//$gt_heading_font: 'Quicksand', sans-serif;

//$gt_body_font: 'Khula', sans-serif;
//$gt_heading_font: 'Khula', sans-serif;

$gt_body_font: 'Muli', sans-serif;
$gt_heading_font: 'Muli', sans-serif;

//$gt_body_font: 'Poppins', sans-serif;
//$gt_heading_font: 'Poppins', sans-serif;

$font-color: #333;

$btn-font-weight: 400 !important;
$btn-font-size: 15px;

$gt_font-size-biggest: 17px;
$gt_font-size-bigger: 16px;
$gt_font-size: 15px;
$gt_font-size_text: 14px;
$gt_font-size-smaller: 13px;
$gt_font-size-smallest: 12px;

$gt_box_shadow: 0 1px 3px -2px rgba(0,0,0,0.12),0 1px 2px rgba(0,0,0,0.24);
$gt_box_shadow_hover: 0 1px 5px -2px rgba(0,0,0,0.12),0 1px 5px rgba(0,0,0,0.24);

$gt_border_color: #ccc;
