/* ASIDE */

aside img{
	max-width: 100%;
}
aside .aside-head {
	color: #000000;
	font-size: 18px;
	letter-spacing: 1px;
	font-weight: 300;
	margin-top: 20px;
	margin-bottom: 10px;
}
.col-md-4 aside:nth-child(2) {
	border-top: 1px solid #CCC;
	margin-top: 20px;
}
.footer .col-md-4 aside:nth-child(2){
	border: none;
}
aside.aside-green .aside-head, aside .small-slider-nav {
	background: url(../../images/bg_lister_header.jpg) repeat-x left center transparent;
	padding: 20px;

}
aside .small-slider-nav {
	padding: 10px 60px;
}
