
.regions-block {
  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: top;
    justify-content: start;

    list-style-type: none;
    margin: 0;
    padding: 0;

    &.regions {
      li {
        margin: 0;
        padding: 1rem;
      }
    }
  }
}
