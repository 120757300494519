
#searchclear {
	position: absolute;
	right: 10px;
	top: 10px;
	z-index: 5;

	cursor: pointer;

  svg {
    height: 20px;
    margin-bottom: -7px;
  }
}

.twitter-typeahead {
	display: block !important;
}

.result-count {
	font-size: 20px;
	line-height: 30px;
	color: $gt_green;
	font-weight: 400;
	margin: 10px 0;
}

@media (max-width: 767px) {
	#zoek-widget.block .block-header {
		padding: 0;
	}
	#triggerFilterCollapse {
		padding: 15px 0;
		font-size: 20px;

    svg {
      fill: #fff;
    }
	}
	#triggerFilterCollapse:not(.collapsed) {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 11;
		.toon-reizen {
			display: block !important;
		}
		.toon-filters {
			display: none !important;
		}
	}
	#triggerFilterCollapse.collapsed {
		.toon-filters {
			display: block !important;
		}
		.toon-reizen {
			display: none !important;
		}
	}
	#filterCollapse.collapse.in, #filterCollapse.collapsing {
		position: fixed;
		top: 88px;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 10;
		background-color: $gt_grey_light;
	}
	.block .block-body .form-group.checkboxes {
		padding: 10px;
	}

}
.toggle-options[aria-expanded="false"] {
	.more {
		display: block;
	}
	.less {
		display: none;
	}
}
.toggle-options[aria-expanded="true"] {
	.more {
		display: none;
	}
	.less {
		display: block;
	}
}
#scrollable {
    overflow: auto;
    max-height: 90%;
    .close {
    	position: absolute;
		right: 10px;
		top: 10px;
		color: $gt_green;
		opacity: 0.8;
    }
}

.toggle-filter {
	cursor: pointer;

	svg {
		float: right;
		line-height: 1.5;
    height: 14px;
    fill: $gt_green;

    transition-duration: 0.2s;
    transition-property: transform;
	}

  &.collapsed {
    svg {
      transform: rotate(-90deg);
    }
  }

  @media (max-width: 767px) {
		border: 1px solid $gt_grey;
		padding: 10px;
	}
}

#search-collapse {
	.form-container {
		position: relative;
		height: 100%;
		.zoek-widget {
			z-index: 1041;
			.btn-group {
				display: block;
			}
		}
	}
}
.selected-filters {
	.selected-filter {
		border: 1px solid $gt_green;
		border-radius: 4px;
		padding: 5px 10px;
		display: inline-block;
		margin-right: 10px;
		margin-bottom: 10px;
		font-size: 12px;
		line-height: 1.5;
		color: $gt_green;
		&:hover {
			background-color: $gt_green;
			color: #fff;
		}
		i {
			line-height: 1.5;

		}
	}


}
#searchform {
	margin: 40px 0;

	.tt-menu {
		background-color: none;
    margin: 44px 0 1px;
    padding: 0;
    width: calc(100% - 1px);

    z-index: 500;

    flex-flow: row nowrap;
	}

  .tt-open {

    @media (min-width: 767px) {
      display: flex !important;
    }

  }

	.tt-dataset {

    &:empty {
      display: none !important;
    }

    flex: 1 1 auto;
    width: 100%;
		margin-right: 0;

		h3.cat-name {
			color: #000;
			font-weight: 600;
      font-size: 17px;
			padding: 5px;
			margin: 0;
			background-color: $gt_grey;
		}

		.tt-suggestion,
    .empty-message {
			line-height: 1.5;
			margin:0;
			padding: 0;
			color: #000;
			text-align: left;
			border-top: 1px solid $gt_grey;
			padding: 5px;
			background-color: #fff;

      &:last-child {
        border-bottom: 1px solid $gt_grey;
      }
		}
		.tt-suggestion:hover {
			cursor: pointer !important;
			background-color: $gt_green;
			color: #fff;
		}
	}

	.form-control.autosuggest.tt-input {
		position: absolute !important;
		border: none !important;
		padding-right: 32px;
	}
}
/* LISTER PAGE */
.zoek-widget-form {
	.block-body {
		background-color: $gt_grey_light;
	}
}
@media (max-width: 767px) {
	.form-container {
		h1 {
			padding: 0 15px;
		}
		.zoek-widget {
			.form-control::placeholder {
				color: #333;
			}
			.input-lg, .btn-lg {
				font-size: $gt_font-size * 0.9;
			}
			.btn-lg {
				height: 46px;
			}

		}
	}
	#searchform .tt-dataset h3 {
		padding: 2px 5px;
		font-size: $gt_font-size;
		text-transform: none;
		text-align: left;
	}
}
