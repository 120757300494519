/* FOOTER */
footer {
	
	.pre-footer {
		padding: 20px 0 40px;
		aside {
			text-align: center;
		}
		
	}
	.footer {
		padding: 40px 0;
		background-color: $gt_grey_light;
	}
		.footer .small-slider-image{
			background-color: #eaeaea;
		}
		.footer .keurmerken a img {
			margin-right: 10px;
		}
		
		.footer .contact a {
			color: #8e8e8e;
		}
	.footer-well{
		background: #ececec;
		border: 1px solid #cecece;
		border-radius: 2px;
		padding: 15px;
		margin: 20px 0;
	}
		.footer-well h3{
			margin: 0 0 15px 0;
		}
	aside .footer-list, aside .menu{
		list-style: none outside none;
		margin: 0 0 20px 0;
		padding: 0
	}
	.aside-head {
		font-size: 15px;
		font-weight: 400;

    svg {
      height: 15px;
      margin-bottom: -2px;
    }
	}
		.advisors {
			.advisor-image {
				width: 70px;
				height: 70px;
				border-radius: 35px;
				
			}
		}
		
		aside .footer-list.inline li {
			display: inline-block;
			margin-right: 10px;
			line-height:20px;
			
		}
			aside .footer-list li a, aside .menu  li a, .footer-item {
				color: #333;
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
	.keurmerken {
		img {
			margin: 0 10px;
		}
	}
	.kv-rating {
		font-weight: 600;
	}
			
}
.package-box {
	margin-bottom:20px
}
.package-box .package-box-title {
	background-color:$gt_green;
	padding:15px;
	height:70px
}
.package-box .package-box-title h4 {
	color:#fff;
	text-align:center;
	margin:0 auto
}
.package-box .package-box-body {
	padding:15px;
	background-color:#fff;
	font-size:13px;
	color: #555;
}
.package-box .package-box-body ul {
	padding-left:10px;
	li {
		color: #555;
	}
}
