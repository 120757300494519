.lister-item-acco {


  .lister-item-right {

		.acco-usps {
			padding-right: 30px
		}

    &.height-1 {
			padding-bottom: 47px;
		}

    &.height-2,
    &.height-3,
    &.height-4 {
			padding-bottom: 94px;
		}
	}

  .acco-packages {
		left: 0;
		right: 0;
		width: 100%;
		position: absolute;
		bottom: 0;
		margin-top: 10px;

  	.package {
			background-color: #fff;
			padding: 5px 10px;
			color: $gt_black;
			border-top: 1px solid $gt_grey;
			.package-name {
				border-bottom: 1px solid $gt_green;
			}
			.package-days-price {
				font-weight: 600;
			}
			.package-cta {
				margin: 3px 0;
			}
			&:nth-child(n+3) {
				display: none;
			}
			
		}
		
		.more-packages {
			display: block;
			text-align: center;
			color: $gt_green;
			cursor: pointer;
		}
		
	}
	
	.show-all {
		background-color: rgba(0,0,0,0.6);	
		.package:nth-child(n+4) {
			display: block;
		}
		.more-packages i:before {
			content: "\f078" !important;
			color: #fff;
		}
	}
}
