.partners {
	display: flex;
	align-items: stretch;
	flex-wrap: wrap;
	margin-bottom: 40px;
	.partner {
		padding: 20px;
		flex-basis: 25%;
		display: flex;
		justify-content: flex-end;
		flex-direction: column;
	}
}