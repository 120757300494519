.form-block {
	background-color: $gt_grey_light;
	// padding: 15px;
	margin-bottom: 20px;

	// @media (max-width: 767px) {
	// 	padding-bottom: 40px;

  //   padding-left: 0;
  //   padding-right: 0;
  //   margin-left: -15px;
  //   margin-right: -15px;

  //   &#reisoverzicht {
  //     padding: 15px;
  //     margin: 0;
  //   }

	// }

  .form-title {
		margin: 5px 0;

	}
	h4.form-title {
		font-size: 14px;
		color: $gt_black;
	}
	.room-group {
		.control-label {
			text-align: left !important;
		}
	}
	.greenfee-group {
		margin-bottom: 15px;
	}
}

.ui-widget-header .ui-icon {
	background-image: url(../../images/ui-icons_444444_256x240.png);
}


label {
  font-weight: normal;

  &.required {
    font-weight: 700;

    &::after {
      content: " *";
    }
  }
}

.radio {
  label {
    &.required {
      &::after {
        content: "";
      }
    }
  }
}

input[type="date"].form-control, input[type="datetime-local"].form-control, input[type="month"].form-control, input[type="time"].form-control {
	line-height: inherit;
}

input, select, textarea {
  // &:invalid,
  &.error {
    border-color: #ff4147;
  }

  &[required]:valid {
    border-color: #d6e9c6;
  }
}

.aanvraag-container {

  // > .row {
  //   display: flex;
  //   align-items: stretch;

  //   .col-md-6 {
  //     flex: 1 0 auto;
  //     align-self: flex-start;
  //   }
  // }

  .sticky-container {
    position: sticky;
    top: 0;
  }

  .reisoverzicht {
    background-color: #f5f5f5;
    padding: 1.5rem;
    margin-top: 3.5rem;
    max-height: 80vh;
    overflow-y: auto;
  }

  table.package-details {
    margin: 1.5rem 0 0;
  }

  .form-group.buggy {

    div.popover {
      min-width: 275px;
    }

    span[data-toggle='popover'] {
      display: inline-block;
      margin-left: 5px;
      margin-top: 7px;
      cursor: pointer;
    }

  }
}


select > option {
  &:disabled {
    display: none;
    visibility: hidden;
    opacity: 0;
    width: 0;
    height: 0;
    overflow: hidden;
  }
}


@media (max-width: 991px) {

  #book_request_save,
  #toggleOverzichtButton {
    position: fixed;
    bottom: 0px;
    width: 50%;
    z-index: 10;
    border: 1px solid $gt_grey_dark;
  }

  #book_request_save {
    left: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;

  }

  #toggleOverzichtButton {
    right: 0;
    border-left: 1px solid $gt_black;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  .aanvraag-container {
    width: 100%;
    margin-bottom: 8rem;

    .form-container {
      width: 100%;
    }

    .sticky-container {
      display: none;
      position: fixed !important;
      background-color: #f5f5f5;

      left: 0;
      right: 0;
      bottom: 46px;
      top: 80px;
      z-index: 999;

      min-width: 360px;

      margin: 0;
      padding: 0;

      overflow-y: auto;

      .reisoverzicht {
        max-height: unset;

        .close i {
          opacity: 1;
          color: $gt_green;
        }
      }
    }
  }
}
