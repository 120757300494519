.btn, .wysija-submit {
	font-weight: $btn-font-weight;
	padding-left: 9px 20px;
	text-align: center;
	text-decoration: none;
}
.btn-primary, .wysija-submit {
	background-color: $gt_red;
	border: 1px solid $gt_red;
	font-family: $gt_heading_font;
}
.btn-primary:hover, .wysija-submit:hover, .btn-primary.active, .btn-primary:active, .btn-primary:focus, .btn-primary:visited, .open > .btn-primary.dropdown-toggle  {
	background-color: darken($gt_red, 5%);
	border: 1px solid darken($gt_red, 5%);
}
.btn-secondary {
	background-color: $gt_green;
	border: 1px solid $gt_green;
	color: #FFFFFF;
	font-weight: 600;
}
.btn-secondary:hover, .btn-secondary:focus  {
	background-color: darken($gt_green, 5%);
	border: 1px solid darken($gt_green, 5%);
	color: #fff;
}
.btn-white {
	background-color: #fff;
	border: 1px solid $gt_green;
	color: $gt_green;
	font-weight: 700;
	&:hover {
		background-color: $gt_grey_light;
		color: $gt_green;
	}
}
.btn-default {
	background: #fff;
	color: $gt_black;
	&:hover {
		background-color: $gt_grey;
		color: $gt_black;
	}
}
.btn.no-radius {
	border-radius: 0;
}
.btn-xl {
	font-size: 20px;
	line-height: 1.33;
	padding: 16px 25px;
}
.btn.btn-xs {
	font-size: 12px;
	letter-spacing: 1px;
}

.sub-btn {
	display: block;
	color: #FFF	;
	margin-top: 10px;
	font-size: 12px;
	line-height: 15px;
}
.home .sub-btn {
	color: #000;
}
.btn-group-lg > .btn, .btn-lg {
	border-radius: 4px;
}
.btn-link {
	color: $gt_green;
}