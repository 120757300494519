// @import "vendor/fontawesome/fontawesome.scss";
// @import "vendor/fontawesome/brands.scss";
// @import "vendor/fontawesome/regular.scss";
// @import "vendor/fontawesome/solid.scss";
// @import "vendor/fontawesome/v4-shims.scss";

// @import url('https://fonts.googleapis.com/css?family=Muli:200,300,400,600,700&display=swap');

h1, h2, h3 {
	letter-spacing: 1px;
	font-family: $gt_heading_font;
	font-weight: 300;
	color: $gt_green;
	line-height: $line-height-base;
}

h1 {
	color: $gt_black;
}


strong,
.strong {
  font-weight: 700;
}
