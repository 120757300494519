
.paginate_button {
	padding: 0 !important;

  &:hover {
    background: none !important;
    border: 1px solid transparent !important;
  }
}

.pagination {
	margin: 10px 20px 10px 0;

	 > li > a,
   .pagination > li > span {
		 color: $gt_green;
	 }


	> .active > a,
  .pagination > .active > span,
  > .active > a:hover,
  > .active > span:hover,
  > .active > a:focus,
  > .active > span:focus {
	    background-color: $gt_green;
	    border-color: $gt_green;
	}
}
