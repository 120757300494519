// @import '../../node_modules/jquery-ui/themes/base/all.css';
// @import '../../node_modules/blueimp-gallery/css/blueimp-gallery.css';
// @import '../../node_modules/datatables/media/css/jquery.dataTables.css';
// @import 'vendor/dops/dops';

$slick-font-path: "~slick-carousel/slick/fonts/" !default;
$slick-loader-path: "~slick-carousel/slick/" !default;

@import '~slick-carousel/slick/slick';
// @import '~slick-carousel/slick/slick-theme';

// @import '~weather-icons/weather-icons';

@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import "~bootstrap-sass/assets/stylesheets/bootstrap/mixins";

@import "~bootstrap-sass/assets/stylesheets/bootstrap/normalize";
// @import "~bootstrap-sass/assets/stylesheets/bootstrap/print";
// @import "~bootstrap-sass/assets/stylesheets/bootstrap/glyphicons";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/type";
// @import "~bootstrap-sass/assets/stylesheets/bootstrap/code";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/grid";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/tables";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/forms";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/buttons";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/component-animations";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/dropdowns";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/button-groups";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/input-groups";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/navs";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/navbar";
// @import "~bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/pagination";
// @import "~bootstrap-sass/assets/stylesheets/bootstrap/pager";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/labels";
// @import "~bootstrap-sass/assets/stylesheets/bootstrap/badges";
// @import "~bootstrap-sass/assets/stylesheets/bootstrap/jumbotron";
// @import "~bootstrap-sass/assets/stylesheets/bootstrap/thumbnails";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/alerts";
// @import "~bootstrap-sass/assets/stylesheets/bootstrap/progress-bars";
// @import "~bootstrap-sass/assets/stylesheets/bootstrap/media";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/list-group";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/panels";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/responsive-embed";
// @import "~bootstrap-sass/assets/stylesheets/bootstrap/wells";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/close";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/modals";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/tooltip";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/popovers";
// @import "~bootstrap-sass/assets/stylesheets/bootstrap/carousel";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/utilities";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities";

// @import "~cookieconsent/build/cookieconsent.min.css";
@import "~vanilla-cookieconsent/dist/cookieconsent.css";

@import '~jquery-ui/themes/base/theme.css';
@import '~jquery-ui/themes/base/datepicker.css';

@import 'variables';

@import 'aside';
@import 'block-widget';
@import 'buttons';
@import 'calendar';
@import 'campaign';
@import 'cookie-notice';
@import 'default';
@import 'detail-page';
@import 'footer';
@import 'fonts';
@import 'forms';
@import 'gallery';
@import 'golfbanen';
@import 'header';
@import 'icons';
@import 'lister-item';
@import 'lister-item-acco';
@import 'lister-item-blog';
@import 'lister-item-golfbaan';
@import 'location-page';
@import 'modal';
// @import 'news';
@import 'offer';
@import 'packages';
// @import 'page';
@import 'pagination';
@import 'panel';
@import 'partner';
@import 'review';
@import 'search-widget';
// @import 'single';
@import 'slick-carousel';
@import 'slider';
// @import 'small-slider';
@import 'tabs';
// @import 'wpcf7';
@import 'destinations';



// shame!..

svg {
  height: 16px;
  fill: $font-color;
}

#weather {
  table {
    width: 100%;
  }
}

a.package-cta {
  svg {
    fill: #fff;
  }
}

.detail-page {
  h1 {
    svg {
      height: 12px;
      fill: $gt_green;
      margin-bottom: 6px;
    }
  }
}

.lister-item-right {
  .title-stars {
    svg {
      height: 10px;
      fill: $gt_green;
      margin-top: 2px;
    }
  }
}


.current-weather {
  svg {
    fill: #fff;
    height: 12rem;
  }
}

.current-weather-details {
  svg {
    fill: #fff;
    height: 1.75rem;
    margin-bottom: -3px;
  }
}

.forecast-weather {
  svg {
    display: block;
    fill: #ccc;
    height: 40px;
    margin: 0 auto -3px;
  }
}

#detail-carousel {
  .slick-arrow {
    svg {
      fill: #fff;
      height: 30px;
    }
    background-color: rgba(28, 74, 64, 0.8);
    border-radius: 50%;
    padding: 10px;
    border: 1px solid #fff;

    &.slick-prev-alt {
      padding-left: 14px;
      padding-right: 18px;
    }

    &.slick-next-alt {
      padding-left: 18px;
      padding-right: 14px;
    }
  }
}

.lister-item-medium {
  .slick-arrow {
    &.slick-prev-alt {
      left: 30px;
    }

    &.slick-next-alt {
      right: 30px;
    }

    svg {
      fill: #fff;
      height: 30px;
    }
  }
}

.lister-item-small {
  .slick-arrow {
    svg {
      display: none;
    }
  }
}

span.more-media {
  svg {
    fill: #fff;
    height: 25px;
    margin-bottom: -2px;
  }
}

button {
  &.btn-primary {
    svg {
      fill: #fff;
      margin-bottom: -3px;
    }
  }
}

.slick-slider {
  .carousel-item {
    .carousel-bottom {
      position: absolute;
      bottom: 0;
      background-color: rgba(0,0,0,0.5) !important;
      width: 100%;
      padding: 10px;
      color: #fff;
      font-size: 20px;
      line-height: 1.2;
    }
  }
}

.info-box {
  svg {
    fill: $gt_green;
    height: 40px;
  }
}


.info-item6 {
  ul {
    li {
      list-style: none;
    }
  }
}

.package-icon {
  svg {
    fill: $gt_green;
  }
}

ul.custom-list-image {
  li {
    padding: 0 0 2px;
    svg {
      fill: $gt_green;
      margin-bottom: -2px;
      margin-left: -25px;
      margin-right: 10px;
    }
  }
}

.nav-tabs {

  li {
    &.active {
      a {

        &:hover {
          svg {
            fill: $gt_green;
          }
        }
      }
    }

    a {
      svg {
        fill: $gt_green;
        margin-bottom: -3px;
        margin-right: 3px;
        height: 15px;

        @media (min-width: 992px) and (max-width: 1199px) {
          display: none;
        }
      }

      &:hover {
        svg {
          fill: #fff;
        }
      }

    }
  }
}

.listerpromo {
  position: relative;
  margin-bottom: 2rem;
  max-width: 800px;

  img {
    width: 100%;
    height: 28rem;
    object-fit: cover;
    // object-position: 50% %;
  }

  &--title {
    position: absolute;
    top: 1rem;
    background-color: $gt_grey;
    padding: 1rem 2rem;

    h1 {
      margin: 0;
      padding: 0;
    }
  }

  &--ctabox {
    position: absolute;
    bottom: 1rem;
    text-align: right;
    right: 1rem;

    a {
      &:hover {
        box-shadow: $gt_box_shadow_hover;
      }
    }

    p {
      color: #fff;
      text-shadow: 2px 2px 4px #000000;
      font-weight: bold;
      font-size: 1.7rem;
    }
  }
}


#homepage-slider {
  .photo-title {
    text-shadow: 2px 2px 4px #000000;
    // font-weight: bold;
  }
}


.alert {
  &-error {
    @extend .alert-danger;
  }
}

#cc-container {
  .cc-window {
    z-index: 999999;
    border-top: 1px solid #1c4a40;
    background-color: #fff;
    box-shadow: 0 -5px 15px rgba(0,0,0,0.3);
  }

  .cc-btn {
    border: 1px solid #1c4a40;
  }

  .cc-allow {
    background-color: #1c4a40;
    color: #fff;

    &:hover {
      text-decoration: underline;
    }
  }

  .cc-revoke {
    visibility: hidden !important;
    background-color: #fff;
    left: 1rem;
    svg {
      fill: $gt_green;
    }
  }
}


div.viewport-size-helper {
  position: fixed;
  left: 0;
  bottom: 7.5rem;
  background-color: rgba(255,255,255,0.5);
  padding: 10px;
  color: #000;
  font-weight: 600;
  border: 1px solid rgba(0, 0, 0, 0.5);
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-property: opacity, top, bottom;
}

// hide google recaptcha
div.grecaptcha-badge {
  visibility: hidden !important;
}

.embed-responsive-66 {
  padding-bottom: 66.7%;
}

.action-banner-row {
  background-color: $gt_green;
  color: #fff;

  h1, h2, h3, h4, h5, h6 {
    color: #fff;
    font-weight: bold;
    margin-top: 0;
  }

  .container {
    padding: 15px;

    a {
      color: $gt_red;
      // font-weight: bold;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 991px) {
  iframe#launcher {
    bottom: 6rem !important;
  }
}

/* WP images alignment */

img,
a img {
  &.alignright {
    float: right;
    margin: 0 0 1em 1em;
  }

  &.alignleft {
    float: left;
    margin: 0 1em 1em 0 !important;
  }

  &.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto
  }
}


figure.hero {
  margin: 20px 0 0;
  padding: 0;


  img {
    padding: 0;
    margin: 0 !important;

    object-fit: cover;
    object-position: center;
    max-height: 40vh;
    width: 100%;
  }
}

body.homepage {

  aside {
    section {
      &:nth-child(odd) {
        background-color: $gt_grey_light;
      }
    }
  }
}

body.golfpro-trips {
  .lister-item-right {
    min-height: auto;

    &.height-2 {
      padding-bottom: 5px !important;
    }
  }
}


.detail-theme {
  .hero {
    figure {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      width: 100%;
      height: 20em;
      margin-bottom: 16px;
    }
  }
}


.countdown-container {
  margin: 0;
  padding: 0;

  .countdown-title {
    text-align: center;
    font-weight: bold;
    color: $gt_green;
    font-size: 90%;

    margin-bottom: 8px;
  }

  .countdown-timer {
    color: $gt_green;
    font-weight: bold;

    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: center;
    align-items: center;

    &__item {

      &__label {
        text-align: center;
        font-size: 7px;
      }

      &__value {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        padding: 5px 10px;
        border-radius: 4px;

        background-color: $gt_red;
        color: #fff;
      }

    }

    .divider {
      font-size: 18px;
      font-weight: bold;
      margin-top: -10px;
    }
  }

}
