/* Modal
------------------------------ */


.modal {
	.modal-header {
		background-color: $gt_green;
		color: #FFF;
		.close {
			color: #fff;
			opacity: 1;
			text-shadow: none;
			font-weight: 400;
			font-size: 30px;
		}
	}
	.modal-dialog {
		
		@media (max-width: 768px) {
			margin: 0;
		}
	}

	.panel-group {
		margin-bottom: 0;
	}
}
.modal.offerte .modal-lg {
	width: 1200px;
	max-width: 100%;
}
.packages .package .modal .panel-body {
	padding: 20px;
}
.packages .package .modal .package-specs > ul > li {
	font-size: 15px;
	padding: 10px 0;
}

