.offer-label {
	position: absolute;
	right: 0;
	top: 50px;
	background-color: $gt-red;
	padding: 10px;
	color: #fff;
	font-size: 20px;
	z-index: 2;
	line-height: 1;
}

.lister-item .offer-label {
	font-size: 15px;
	top: 25px;
}

.calendar-wrapper {
  .calendar-day {


    &:not(.active) {
      .offer {
        .price {
          color: $gt_red;
          font-weight: 500;
        }
      }
    }
  }
}

.calendar-container {
  .offer.description {
    color: $gt_red;
    font-weight: 600;
  }

  abbr {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
