.wrapper-rb {
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1;
	.badge-rb {
	    background: $gt_green;
	    display: inline-block;
	    height: 45px;
	    position: relative;
	    text-align: center;
	    width: 80px;
	    color: #fff;
	    font-size: 14px;
	    font-weight: 400;
	    padding: 5px 5px 0 5px;
	}
	.badge-rb:before {
	    border-top: 20px solid $gt_green;
	    border-left: 40px solid transparent;
	    border-right: 40px solid transparent;
	    content: "";
	    height: 0;
	    left: 0;
	    position: absolute;
	    bottom: -20px;
	    width: 0;
	}
}

.icon-sun {
	width: 120px;
	height: 133px;
	position: absolute;
	overflow: hidden;
	z-index: 6;
	right: 0px;
	top: 0px;
	background: url(../../images/icon-sun.png) no-repeat center center transparent;
	background-size: cover;

}
.score {
	text-align: center;
	line-height: 1.5;
	padding: 10px;
	font-size: 20px;
	font-weight: 700;
	background-color: $gt_green;
	color: #fff;
	margin: 0 auto;
	max-width: 60px;
	cursor: pointer;
}
.review-totals {
	margin-bottom: 40px;
}
.review-amount, .review-author {
	font-weight: 700;
}
h3.review-title {
	font-size: 20px !important;
	font-weight: 700;
	font-style: italic;
}
p.review-text {
	font-size: 16px;
}
.alert.alert-warning {
	background-color: #fef271 !important;
}
.lister-item-small {
	.icon-sun {
		width: 80px;
		height: 88px;
	}
}
.mwg {
	padding-right: 85px;
	.icon-sun {
		width: 80px;
		height: 88px;
	}
}


iframe {
	border: 0;
}

html {
	// overflow-x: hidden;
  scroll-behavior: smooth;
  min-width: 360px;
}

@media screen and (max-width: 360px) {
  html {
    overflow-x: scroll;
  }
}

body {
	background-color: #fff;
	font-family: $gt_body_font;
	// position: relative;
	font-size: $gt_font-size;
	font-weight: $gt_font_weight_body;
}
h1,h2,h3 {
	text-transform: none;
}
h1 {
	font-size: 25px;
}
.label-primary {
	background-color: $gt-red;
}
#accordion.panel-group .panel .panel-heading {
	padding: 10px 15px;
	background-color: #fff;
	border-radius: 3px;
	a {
		display: block;
		padding: 0;
		text-decoration: none;
	}
}
.main-img{
	width: 100%;
}
@media only screen and (min-width : 481px) {
    .flex-row {
        display: flex;
        flex-wrap: wrap;
    }
    .flex-row > [class*='col-'] {
        align-self: stretch;
        margin-bottom: 20px;
        > .panel {
        	height: 100%;
        }
    }
}
.no-gutter {
  margin-right: 0;
  margin-left: 0;
}

.no-gutter > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}



.package-box li {
	color: #fff;
}


.ke{
	overflow: hidden;
}
.ke-hidden{
	height:0px;
}
.content {
	h3 {
		font-size: 16px;
		margin-bottom: 0;
	}
	a {
		color: $gt_red;
		text-decoration: underline;
	}
	p {
		font-size: $gt_font-size_text;
	}
}
.text-content {
	font-size: 15px;
}
a {
	text-decoration: none;
	color: $gt_black;
	&:hover {
		text-decoration: none;
		color: $gt_black;
	}
}
img {
	font-size: 12px;
	line-height: 1.2
}
a:hover, a:focus {
	color: $gt_red;
}
.spacer {
	height: 40px;
}
.spacer-20 {
	height: 20px;
}
.spacer-40 {
	height: 40px;
}
.article-widget h4, .article-widget h3, .article-widget h2{
	letter-spacing: 1px;
	font-family: $gt_heading_font;
	font-weight: 300;
	font-size: 14px;
}
.main-content-features{
	background: #f8f9e5;
	padding: 30px 0;
	margin: 40px 0;
	border-bottom: 4px dotted #114f27;

	border-top: 4px dotted #114f27;
}
.main-content{
}
.fancy {
  text-align: center;
  overflow: hidden;
  font-size: 20px;
  margin-top: 30px;
}
.fancy span {
  display: inline-block;
  position: relative;
}
.fancy span:before,
.fancy span:after {
  content: "";
  position: absolute;
  height: 5px;
  border-top: 4px dotted #ccc;

  top: 8px;
  width: 600px;
}

.fancy span:before {
  right: 100%;
  margin-right: 15px;
}
.fancy span:after {
  left: 100%;
  margin-left: 15px;
}
.fa.small {
	font-size: 90%;
}


h2.row-header {
	@media (max-width: 767px) {
		border-top: 4px solid $gt_green;
	}
	span {
		border-top: 4px solid $gt_green;
		@media (max-width: 767px) {
			border-top: none;
		}
		padding-top: 10px;
	}
	font-size: $font-size-h2;
	margin: 40px auto;
	text-align: center;

	padding-top: 10px;
}


.form br,
.wpcf7 br {
	display: none;
}

ul.ui-autocomplete {
	max-height: 310px;
	overflow: scroll;
	top: 30px;
}

ul.custom-list-image {
	list-style: none;

}
.full-width-row {
	padding-bottom: 20px;
	&.green {
		background-color: $gt_green;
		color: #fff;
		a, .aside-head {
			color: #fff;
		}
	}
}
.no-border {
	border: none;
}
.color-row {
	background-color: $gt_red;
	width: 100%;
	height: 15px;
	margin-bottom: 0;
}
.color-row .color {
	width: 25%;
	height: 15px;
	float: left;
}
.color-row .color:nth-child(1) {
	background-color: #6c7bc8;
}
.color-row .color:nth-child(2) {
	background-color: #004f1e;
}
.color-row .color:nth-child(3) {
	background-color: #00a7b8;
}
.color-row .color:nth-child(4) {
	background-color: #00a73a;
}

.list-group-item.active,
.list-group-item.active:focus,
.list-group-item.active:hover {
	background-color: $gt_green;
	color: #fff;
}

.sub-title {
	background-color: #FFFFFF;
	border-radius: 2px;
	color: #004f1e;
	letter-spacing: 1px;
	line-height: 30px;
	font-size: 22px;
	padding: 10px 20px;
	display: block;
	width: -moz-fit-content;
}
.usps {
	background-color: rgba(218,219,217,0.9);
	padding: 20px;
	margin: 0 0 20px 0;
	color: #004f1e;
	font-size: 18px;
	list-style: disc inside none;
}
.usp-block {
	background-color: rgba(218,219,217,0.7);
	.usps {
		background-color: transparent;
	}
	.sub-title {
		background-color: rgba(218,219,217,1);
	}
}
h3.recom-name {
	font-size: 18px;
	padding: 10px;
	margin: 0;
	color: #000000;
}
.popover-item {
	cursor: pointer;
}
.single-reizen .popover {
	max-width: 500px;
}
.popover {
	/* good god man, that's high. better make it important */
	z-index: 100000000000;
}
.home .breadcrumbs {
	display: none;
}
.breadcrumbs {
	margin-top: 15px;
	position: relative;
	z-index: 2;
	font-size: 12px;
	ol {
		list-style: none;
		padding-left: 0;
		li {
			display: inline-block;
			&:last-child a {
				text-decoration: none;
				cursor: default;
			}
		}
		a {
			text-decoration: underline;
			color: $gt_black;

		}
	}
}
.contact {
	font-size: 16px;
	line-height: 25px;
}
#firstHeading {
	margin-top: 5px;
	font-size: 15px;
	width: 225px;
}
.input-100 {
	max-width: 100px;
}

.panel-heading .meta {
	color: #fff;
	font-size: 11px;
}
h1 .meta {
	color: #000;
	font-size: 13px;
}
.bg-green {
	background-color: $gt_green;
}
.bg-green-dark {
	background-color: darken($gt_green, 5%);
	h1, h2, h3 {
		color: #fff;
	}
}
.bg-green-overlap {
	background-color: $gt_green;
	position: absolute;
	height: 250px;
	width: 100%;
	z-index: 0;
}
.bg-light {
	background-color: #f8f9fd;
}
.bg-white {
	background-color: #fff;
}
/* calendar badge */

p.date {
	width: 50px;
	height: 50px;
	text-align: center;
	position: absolute;
	top: 10px;
	right: 10px;
	background: url(../../images/calendar_icon.png) no-repeat center top transparent;
	margin: 0;
}
table p.date {
	position: relative;
	top: inherit;
	right: inherit;
}
p.date .month {
	color: #fff;
	font-size: 12px;
	display: block;
	width: 100%;
	text-align: center;
	padding: 5px 0 0;
	line-height: 1;
}
p.date .day {
	font-size: 30px;
	display: block;
	width: 100%;
	text-align: center;
	line-height: 1;
}
/* MAPS */
.popover-maps {
	width: 200px;
}


/* MAPS */
#map-canvas{
	height: 600px;
	background-color: #eeeeee;
	width: 100%;

	.maps-info-window {
		width: 300px;
		position: relative;
		//overflow: hidden;

		.title {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			background-color: rgba(255,255,255, 0.8);
			padding: 5px;
			a {
				font-size: 15px;
				font-weight: 400;
				color: #000;
			}


		}
		.content {
			img {
				max-width: 100%;
				height: auto;
				margin: 0;
			}
		}
	}
	.gm-ui-hover-effect {
		opacity: 1 !important;
		top: 10px !important;
		right: 25px !important;

		img {
			border: 5px solid $gt_green !important;
			border-radius: 50% !important;
			background-color: #fff !important;
			width: 30px !important;
			height: 30px !important;
		}
	}
}


.map-loader {
	display: block;
	position: absolute;
	width: 100%;
	z-index: 10;
	text-align: center;
	top: 40px;
}
.loader {
	display: none;
}
#main.loading .loader, #golfbanen.loading .loader, #golfbanen-ajax.loading .loader, #golfbanen.loading .loader {
	display: block;
	position: absolute;
	top: 0;
	left:0;
	right: 0;
	bottom: 0;
	background-color: rgba(255,255,255, 0.8);
	z-index: 10;
}
	#main.loading .loader img, #golfbanen.loading .loader img, #golfbanen-ajax.loading .loader img, #golfbanen.loading .loader img{
		position: absolute;
		left: 50%;
		top: 200px;
	}

#see-all-imgs{
	cursor: pointer;
}
#pgen{
	display: none;
}
.hide-this-img{
	display: none;
}

#acco-in-area.hide-all,
#gb-in-area.hide-all{
	height: 470px;
	position: relative;
	overflow: hidden;
	background: transparent url(../../images/fade.png) repeat-x bottom;
}
	#acco-in-area #fader,
	#gb-in-area #fader2{
		display: none;
	}
	#acco-in-area.hide-all #fader,
	#gb-in-area.hide-all #fader2{
		display: block;
		height: 136px;
		width: 100%;
		position: absolute;
		bottom: 0;
		background: transparent url(../../images/fade.png) repeat-x bottom;
	}
		#show-all-accos,
		#show-all-gb{
			top:-20px;
			padding-bottom: 40px;
			font-size: 14px;
			font-size: 100;
		}


span.wpcf7-form-control-wrap {
    position: inherit;
}
#wpcf7-f50-p70-o1 .div.wpcf7-response-output {
	margin: 0 0.5em 1em;
}
.form-group-mobile{
	display: none;
}

/* adviseurs */


.advisor-image {
	position: relative;
}
	.advisor-image img {
		width: 100%;
		height: auto;
	}
	.advisor-image .quote {
		position: absolute;
		bottom: 0;
		line-height: 20px;
		font-size: 14px;
		background-color: rgb(255,255,255);
		background-color: rgba(255,255,255,0.7);
		margin: 0;
		padding:10px 20px;
		width: 100%;
		color: #004f1e;
	}
.single-reisadviseurs .panel {
	height: 225px;
}



/* Newsletter
------------------------------ */

.wysija-input {
	height: 40px;
    padding: 10px;
    background-color: #FFFFFF;
    background-image: none;
    border: 1px solid #CCCCCC;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
    color: #555555;
    display: block;
    font-size: 14px;
    line-height: 1.42857;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    vertical-align: middle;
    width: 100%;
}



/* NIEUWSBRIEF */
#nieuwsbrief-modal{
	z-index: 20000;
}
.modal-dialog.modal-nieuwsbrief{
	width: 500px;
}
#nb-trigger{
	position: fixed;
	right: 0;
	bottom: 0;
}
	#nb-trigger .btn{
		border-radius: 2px 0 0 0;
		font-size: 15px;
		outline: none;
		cursor: pointer;
	}
	#nb-trigger span{
		display: inline-block;
		margin-left: 8px;
		position: relative;
		top: 1px;
	}

#subnav-acco.showit{
	top: 0;
}
#subnav-acco{
	transition: top 0.6s ease-in-out;
	color: #00a7b8;
	overflow: hidden;
	position: fixed;
    height: 45px;
    background: #eee;
    top:-100px;
    font-size: 0;
    z-index: 1049;
    text-align: center;
    line-height: 34px;
    -webkit-box-shadow: 0px 2px 5px 0px rgba(50, 50, 50, 0.2);
    -moz-box-shadow: 0px 2px 5px 0px rgba(50, 50, 50, 0.2);
    box-shadow: 0px 2px 5px 0px rgba(50, 50, 50, 0.2);
    margin: 0 auto;
    left: 0;
    right: 0;
    border-radius: 0 0 5px 5px;
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,f1f1f1+50,f6f6f6+50,e5e5e5+57,e1e1e1+89 */
background: rgb(255,255,255); /* Old browsers */
/* IE9 SVG, needs conditional override of 'filter' to 'none' */
background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iI2YxZjFmMSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iI2Y2ZjZmNiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjU3JSIgc3RvcC1jb2xvcj0iI2U1ZTVlNSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9Ijg5JSIgc3RvcC1jb2xvcj0iI2UxZTFlMSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(241,241,241,1) 50%, rgba(246,246,246,1) 50%, rgba(229,229,229,1) 57%, rgba(225,225,225,1) 89%); /* FF3.6+ */
background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,1)), color-stop(50%,rgba(241,241,241,1)), color-stop(50%,rgba(246,246,246,1)), color-stop(57%,rgba(229,229,229,1)), color-stop(89%,rgba(225,225,225,1))); /* Chrome,Safari4+ */
background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(241,241,241,1) 50%,rgba(246,246,246,1) 50%,rgba(229,229,229,1) 57%,rgba(225,225,225,1) 89%); /* Chrome10+,Safari5.1+ */
background: -o-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(241,241,241,1) 50%,rgba(246,246,246,1) 50%,rgba(229,229,229,1) 57%,rgba(225,225,225,1) 89%); /* Opera 11.10+ */
background: -ms-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(241,241,241,1) 50%,rgba(246,246,246,1) 50%,rgba(229,229,229,1) 57%,rgba(225,225,225,1) 89%); /* IE10+ */
background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(241,241,241,1) 50%,rgba(246,246,246,1) 50%,rgba(229,229,229,1) 57%,rgba(225,225,225,1) 89%); /* W3C */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e1e1e1',GradientType=0 ); /* IE6-8 */
}
.sub-package{
	display: inline-block;
    height: 45px;
    border-right: 1px solid #fff;
    border-left: 1px solid #ccc;
    padding: 0 35px 0 20px;
    font-size: 14px;
}
.sub-package:hover{
	background: rgba(204,204,204,0.6);
	cursor: pointer;
}
.sub-package .euro{
	color: #000;
	display: block;
	font-size: 11px !important;
	position: absolute;
	top:15px;
	opacity: 0.7;
}
.sub-package .euro span{
	font-size: 11px !important;
}
.sub-package-wrap{

	display: inline-block;
	border-left: 1px solid #fff;
    border-right: 1px solid #ccc;
    margin-left: 15px;

}
.sub-package-title{
	border: 0;
}
.sub-package-title:hover{
	background: none;
	cursor: default;
}
.sub-package-wrap a:hover{
	text-decoration: none;
	color: #00a7b8;
}

.list-row  {
	position: relative;
}
	.list-left {
		position: absolute;
		left: 0;
		top: 0;
	}
	.list-right {
		position: relative;
		margin: 0 0 10px 70px;
	}
.list-counter {
	display: block;
	width: 60px;
	height: 50px;
	position: relative;
	z-index: 2;
	text-align: center;
	margin-right: 20px;
}
	.list-counter > span {
		line-height: 50px;
		color: #000;
		font-size: 30px;
	}
	.list-counter:hover > span {
		color: #000;
		text-decoration: none;
	}
	.list-counter-image {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		opacity: 0.5;
		width: 100%;
		height: auto;
		overflow-y: hidden;
		z-index: 1;
	}
	.list-counter .event-date {
	    background: transparent url(../../images/calendar_icon.png) no-repeat scroll center top;
	    text-align: center;
	    width: 60px;
	    height: 50px;
	}
		.list-counter .event-date .month {
		    color: #fff;
		    display: block;
		    font-size: 12px;
		    line-height: 1;
		    padding: 5px 0 0;
		    text-align: center;
		    width: 100%;
		}
		.list-counter .event-date .day {
		    display: block;
		    font-size: 30px;
		    line-height: 1;
		    text-align: center;
		    width: 100%;
		    color: #000;
		}

.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xs-5ths {
    width: 20%;
    float: left;
}

@media (min-width: 768px) {
    .col-sm-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width: 1200px) {
    .col-lg-5ths {
        width: 20%;
        float: left;
    }
}

