	/* LISTER ITEM */

.lister-item {

  //box-shadow: $gt_box_shadow;
	margin-bottom: 20px;
	background-color: $gt_grey_light;
	border: 1px solid $gt_grey_light;
	max-width: 800px;

	&:hover {
		box-shadow: $gt_box_shadow_hover;	
	}

  @media (min-width: 1023px) {
		.row-flex, .col-flex {
			display: flex;
		}
		.col-flex {
			flex-direction: column;
		}
	}

	.lister-item-left {

    img {
      min-height: 200px;
    }

		background-size: cover;
		background-position: center center;
		position: relative;
		overflow: hidden;
		cursor: pointer;

		.lister-item-carousel {
			//min-height: 269px;
		}

    .title-within-img {
			position: absolute;
			top: 5px;
			left: 5px;

			.item-name {
				background-color: rgba(255,255,255, 0.8);
				padding: 5px;
				margin-bottom: 5px;

				a {
					color: $gt_black;
					font-size: $gt_font-size-biggest;
					font-weight: 500;
					text-decoration: none;

          .title-stars {
						font-size: 50%;
						vertical-align: top;
					}
				}
				
			}

			.item-location {
				background-color: rgba(255,255,255, 0.9);
				border-radius: 2px;
				padding: 0 5px 2px 5px;;
				color: $gt_black;
				display: inline-block;
				a {
					color: $gt_black;
					text-decoration: none;
					font-weight: 400;
					font-size: $gt_font-size-smallest;
				}
			}
		}
		
	}

	&.lister-item-vertical .lister-item-right {
		margin-left: inherit;

    .lister-item-acco .acco-packages {
			position: relative;
		}

    &.height-2,
    &.height-3,
    &.height-4 {
		    padding-bottom: 110px;
		}
	}

	.lister-item-right {

    min-height: 200px;

		position: relative;
		overflow: hidden;
		height: 100%;
		@media (min-width: 1023px) {
			margin-left: -30px;
		}
		.score {
			position: absolute;
			top: 10px;
			right: 10px;
			z-index: 5;
		}

    &.with-review h3.item-title, &.with-review .detail-usps {
			padding-right: 100px
		}

    h3.item-title {
			padding: 0 10px;
			position: relative;
			margin: 10px 0 0 0;
			font-size: 15px;
			a {
				color: $gt_green;
				font-weight: 400;
				line-height: 1.2;
			}
		}
		.title-stars {
			color: $gt_green;
			padding-left: 10px;
			font-size: 10px;
		}

    ul.detail-usps {
      li {
        svg {
          margin-left: -15px;
        }
      }
    }
	}
}
.rating {
	position: absolute;
	bottom: 10px;
	right: 10px;
	background-color: #fff;
	width: 80px;
	height: 80px;
	text-align: center;
	.avg_rating {
		font-size: 30px;
		font-weight: 700;
		line-height: 60px;
		color: $gt_green;
		display: block;
		height: 60px;
	}
	.nr_reviews {
		background-color: $gt_green;
		display: block;
		height: 20px;
		color: #fff;
		font-size: 11px;
		line-height: 20px;
	}
}
.acco-aanbevolen, .acco-aanbieding, .package-korting {
	width: 190px;
	font-weight: 400;
	position: absolute;
	text-align: center;
	line-height: 30px;
	letter-spacing: 1px;
	color: #fff;
	top: 30px;
	right: -50px;
	left: auto;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	box-shadow: 0 0 3px rgba(0,0,0,.3);
}
.acco-aanbevolen {
	background-color: $gt_green;
}
.acco-aanbieding {
	background-color: $gt_red;
}

.detail-usps {
	padding: 10px;
	margin: 0;

	list-style: none;
	transform: none !important;
	text-align: left !important;

  li {
		list-style-type: none;
		list-style-position: inside;
		padding-left: 15px;


    &:nth-child(n+4){
			display: none;
		}

    svg {
      height: 12px;
      fill: $gt_green;
      margin-bottom: -2px;
      margin-left: -20px;
      margin-right: 5px;
    }

		i {
			color: $gt_green;
			position: absolute;
			left: 0;
			top: 5px;
			font-size: 11px;
		}
	}
}

.lister-item-small {
	.lister-item-right h3.item-title {
	    padding: 0 10px;
	    margin: 5px 0 0 0;
	    font-size: 14px;
	}
	&.lister-item-golfbaan .lister-item-right h3.item-title {
		width: 75%;
	}
	.acco-aanbieding, .acco-aanbevolen {
		display: none;
	}
	.detail-usps {
		font-size: 12px;

		li:nth-child(n+2) {
			display: none;

      svg {
        height: 12px;
        fill: $gt_green;
      }
		}
	}

  .acco-packages,
  .acco-packages .btn,
  .golfbaan-desc {
		font-size: 12px;
		font-weight: 100 !important;
	}
}

.golfbaan-rating {
		.golfbaan-rating-number {
			font-size: 16px;
			line-height: 20px;
		}
		.golfbaan-rating-holes {
			font-size: 13px;
			padding: 5px 8px;
		}
		.golfbaan-rating-stars i {
			font-size: 8px;
			letter-spacing: -2px;
		}
	}
