/* PANELS */
.panel-black {
	border: 1px solid #ccc;
	.panel-heading {
		background-color: $gt_grey;
		color: #000;
		font-size: $gt_font-size-bigger;
		margin: 0;
		line-height: 1.5;
		border-radius: 0;
		padding: 7px 15px 5px;	
		
	}
	.panel-body {
		background-color: #FFF;
		border-radius: 0;
	}
}
.panel {
	border-radius: 0;
	> .panel-heading, > .panel-footer {
		border-radius: 0;
	}
}
.panel-group .panel .panel-heading {
	padding: 0;
	a {
		padding: 10px 15px;
	}
}
.panel-default {
	border: 1px solid #ccc;
	> .panel-heading {
		h4 {
			margin: 0;
		}
		background-color: $gt_grey_light;
		color: $gt_green;
		&.sub {
			background-color: #fff;
			color: $gt_black;
			padding: 5px 10px;
			font-size: $gt_font-size-smaller;
		}
	}

}

.panel-travelcaddy {
	text-align: center;
	.advisor-image {
		img {
			border-radius: 50%;
			width: 100px;
			height: 100px;
		}
	}
	.advisor-name {
		font-size: $gt_font-size-smaller;
		> div {
			font-weight: 500;
			line-height: 1.5;
			font-size: $gt_font-size-bigger;
			text-decoration: underline;
		}
	}
	.advisor-text {
		font-size: $gt_font-size-smaller;
		border-top: 1px solid $gt_grey;
		border-bottom: 1px solid $gt_grey;
		margin: 20px 0;
		padding: 10px;
	}
}


.page-template-page-contact-php .panel-black {
	height: 245px;
}
.panel-title > a:hover, .panel-title > a:focus {
    color: #666;
}

.panel-grey {
	border: none;
	.panel-heading {
		background-color: $gt_grey;
		color: #000;;
		a {
			text-decoration: none;
			text-align: left;
			display: block;
			&:not(.collapsed) i {
				transform: rotate(90deg);
			}
		}
		
	}
	.panel-body {
		background-color: #FFF;
		border-radius: 0;
		border: none;
	}
}