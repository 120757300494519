.lister-item.lister-item-blog {


	.lister-item-right {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		.item-title {
			padding: 0 10px;
			margin-bottom: 20px;
		}
		.item-excerpt {
			padding: 0 10px;
			a {
				color: $gt_green;
				font-weight: 400;
			}
		}	
		.item-meta-data {
			background-color: #fff;
			border-top: 1px solid $gt_grey;
			border-left: 1px solid $gt_grey;
			padding: 15px 10px;
			align-items: flex-end;
			display: flex;
			justify-content: space-between;
			> div {
				margin-right: 10px;
				text-align: center;
				
				.block-link {
					display: block;
					text-align: right;
					padding: 7px 0;
					i.fa-caret-right {
						font-size: 26px;
						color: $gt_green;
					}
				}
			}
			.author-img img {
				width: 40px;
				height: 40px;	
			}
			.meta-right {
				display: flex;
				align-items: flex-start;
				flex-direction: column;
				justify-content: space-between;
				line-height: 1.3;
				font-size: 11px;
				color: $gt_black;
				> div {
					align-items: flex-end;
				}
			}
		}
	}


}