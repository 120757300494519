/* TABS */


.tab-pane {
	background: #ffffff;
	padding: 20px;
	border-bottom: 2px solid #ddd;
	border-left: 2px solid #ddd;
	border-right: 2px solid #ddd;
	margin-bottom: 20px;

  h1 {
    color: #000;
    font-size: 20px;
  }

  h2 {
    color: #000;
    font-size: 18px;
  }

  h3 {
    color: #000;
    font-size: 16px;
  }

  h4 {
    color: #000;
    font-size: 14px;
  }
}

.nav-tabs {
  > li {
    > a {
      color: $gt_green;
      // font-size: 14px;
      padding: 10px;

      &:hover {
        opacity: 1;
        background-color: $gt_green;
        color: #fff;
      }
    }

    &.active {
      > a,
      > a:hover,
      > a:focus {

        background-color: #FFFFFF;
        color: $gt_black;
        opacity: 1;
        text-decoration: none;
        font-weight: 400;
        border: 2px solid #ddd;
        border-bottom-color: transparent;
      }
    }
  }
}

.panel-group {
  &.responsive {
    .panel-heading {
      color: $gt_green;
      border-radius: 0;
    }
  }
}


//css to add hamburger and create dropdown in tabs

.nav-tabs.nav-tabs-dropdown,
.nav-tabs-dropdown {

  @media (max-width: 767px) {
    border: 1px solid #ddd;
    background-color: #fff;
    width: 100%;
    overflow: hidden;
    position: sticky;
    top: 85px;
    z-index: 999;

    .nav-menu-button {
      position: relative;

      > svg {
        fill: $gt_green;

        position: absolute;
        right: 15px;
        top: 15px;
        z-index: 1003;
      }
    }


    &.open {
      a {
        position: relative;
        display: block;
        color: inherit;
      }
    }


    li {
      display: block;
      width: 100%;
      padding: 0;
      background-color: #fff;

      > a {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        width: 100%;
        height: 100%;
        display: inline-block;
        border-color: transparent;
        z-index: 1;
        background-color: #fff;

        &:focus,
        &:hover,
        &:active {
          border-color: transparent;
        }

        > svg {
          width: 20px;
          max-height: 20px;
          height: auto;
          fill: $gt_green;
        }
      }

      &.active {
        > a {
          border-color: transparent;
          position: relative;
          z-index: 3;
          color: $gt_black;

          &:focus,
          &:hover,
          &:active {
            border-color: transparent;
          }
        }
      }
    }

    &.sticky  {
      position: fixed;
      top: 0;
      width: 100%;
      left: 0;
      z-index: 120;
      background-color: #fff;
    }
  }
}



#page-nav-menu {
	background-color: #fff;;
	border: none;
	z-index: 1049;

  #page-nav {
		width: 100%;
		ul {
			float:none;
      margin: 0 auto;
      display: table;
      table-layout: fixed;

      li {
        a {
          color: #000;
          font-weight: 700;
          &:hover{
            background-color: $gt_green;
            color: #fff;
          }
        }

        &.active {
          a{
            background-color: $gt_green;
            color: #fff;
          }
        }
      }
		}

	}
}

