/* GOLFBANEN */

#additional-info{
	background-color: #fff;
	padding: 20px 0;
	margin: 20px 0;
	border-top: 4px dotted #CCC;
}
	#additional-info .col-lg-3{
		padding-left: 20px;
		padding-right: 20px;
	}
	#additional-info li,
	#additional-info ul{
		list-style: none;
		padding: 0;
		margin: 0;
	}
		#additional-info li{
			position: relative;
			padding-left: 15px;
		}
		#additional-info li i{
			color: $gt_red;
			position: absolute;
			left: 0;
			top:2px;
		}
.golfbaan-content, .golfbaan-content h3 {
		padding: 20px 40px 0 0;
	}
	.golfbaan-content h3 {
		font-size: 16px;
		line-height: 20px;
		text-transform: none;
		font-weight: 400;
	}	
		

@media (min-width: 768px) and (max-width: 979px){

}